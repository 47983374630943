/**********************************************************/
/*    Typography
/*****************************************************************/
@font-face {
  font-family: 'LibreFranklin-Light';
  src: url('../fonts/LibreFranklin-Light.ttf');
  //src: url('../../../assets/fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'LibreFranklin-Medium';
  src: url('../fonts/LibreFranklin-Medium.ttf');
}

@font-face {
  font-family: 'LibreFranklin-Regular';
  src: url('../fonts/LibreFranklin-Regular.ttf');
}

@font-face {
  font-family: 'LibreFranklin-SemiBold';
  src: url('../fonts/LibreFranklin-SemiBold.ttf');
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot');
  src:  url('../fonts/icomoon.eot') format('embedded-opentype'),
    url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.woff') format('woff'),
    url('../fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.ttf');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url('../fonts/Mate-Icon.woff2') format('woff2'),

}