[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: $icon-font-size;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Logo:before {
  content: "\e900";
}
.icon-users:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e909";
}
.icon-user:before {
  content: "\e902";
}
.icon-list:before {
  content: "\e926";
}

.icon-phone:before {
  content: "\e93f";
}

.icon-filter:before {
  content: "\e91f";
}

.icon-mail-unread:before {
  content: "\e916";
}

.icon-chevron-left:before {
  content: "\e91b";
}

.icon-chevron-right:before {
  content: "\e91c";
}

.icon-chevron-down:before {
  content: "\e90d";
}

.icon-chevron-up:before {
  content: "\e90e";
}

.icon-info-filled:before {
  content: "\e913";
}

.icon-info:before {
  content: "\e914";
}

.icon-tick-circle-filled:before {
  content: "\e918";
}

.icon-tick-circle-outline:before {
  content: "\e919";
}

.icon-tick:before {
  content: "\e91a";
}

.icon-calendar-today:before {
  content: "\e942";
}

.icon-close:before {
  content: "\e91d";
}

.icon-edit:before {
  content: "\e94b";
}

.icon-calendar:before {
  content: "\e903";
}

.icon-caution:before {
  content: "\e90c";
}

.icon-documents:before {
  content: "\e906";
}

.icon-facebook-f:before {
  content: "\e946";
  color: #354454;
}

.icon-instagram:before {
  content: "\e947";
  color: #354454;
}

.icon-tiktok:before {
  content: "\e948";
  color: #354454;
}

.icon-youtube:before {
  content: "\e949";
  color: #354454;
}

.icon-baby-solid:before {
  content: "\e94a";
}

.icon-expand:before {
  content: "\e91e";
}

.icon-child:before {
  content: "\e94c";
}

.icon-weChat:before {
  content: "\e94d";
  color: #354454;
}