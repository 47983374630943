@mixin font-size-setter($data) {
  html * {
    font-size: map-get($data, root-fs);
  }

  h1 {
    font-size: map-get($data, h1-fs);
  }

  h2 {
    font-size: map-get($data, h2-fs);
  }

  h3 {
    font-size: map-get($data, h3-fs);
  }

  h4 {
    font-size: map-get($data, h4-fs);
  }

  h5 {
    font-size: map-get($data, h5-fs);
  }

  h6 {
    font-size: map-get($data, h6-fs);
  }

  .circle {
    font-size: map-get($data, root-fs);
  }
}

@mixin container-spacing($data) {
  .main-container-res {
    padding: map-get($data, main-container-padding);
  }


  .two-col-container {
    grid-template-columns: minmax(map-get($data, left-column-width), 10%) auto;
  }

  .left-column {
    width: map-get($data, left-column-width);
  }

}


@mixin components-sizing($data) {
  .mat-raised-button {
    height: map-get($map: $data, $key: button-height);
  }

  .circle {
    height: map-get($data, css-icon-size);
    width: map-get($data, css-icon-size);
    min-height: map-get($data, css-icon-size);
    min-width: map-get($data, css-icon-size);
    line-height: map-get($data, css-icon-size);
  }
}

@mixin responsive-setter($data) {
  $breakpoint: map-get($data, break-point);

  @media screen and (max-width: $breakpoint) {
    $fs-map: map-get($data, fs-map);
    $spacing-map: map-get($data, spacing-map);
    $component-data: map-get($data, component-data);

    @if ($fs-map) {
      @include font-size-setter($fs-map);
    }
    @if ($spacing-map) {
      @include container-spacing($spacing-map);
    }
   
  }
}

$fs-config: (
  root-fs: $font-size-root,
  tab-fs: $tab-font-size - 4px,
  section-title-fs: $section-title-font-size - 2px,
  icon-fs: $icon-font-size - 2px,
  h1-fs: $font-size-root + 8px,
  h2-fs: $font-size-root + 6px,
  h3-fs: $font-size-root + 4px,
  h4-fs: $font-size-root + 2px,
  h5-fs: $font-size-root,
  h6-fs: $font-size-root - 2px,
);


$spacing-config: (
  main-container-padding: $main-container-padding - 5px,
  section-title-margin: $section-title-margin - 5px,
  slider-container-margin: $slider-container-margin - 10px,
  table-header-padding: $table-header-padding - 5px,
  left-column-width: $left-column-width - 95px,
  launcher-tab-margin: $launcher-tab-margin - 30px,
  launcher-icon-margin: $launcher-icon-margin - 15px
);


$components-config: (
  button-height: $mat-button-height - 3px,
  css-icon-size: $css-icon-size - 10px
);

$data: (
  break-point: 1440px,
  fs-map: $fs-config,
  spacing-map: $spacing-config,
  component-data: $components-config
);

@include responsive-setter($data);
