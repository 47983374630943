@import "./global/base/variables";
@import "./global/base/color";


.wordWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-column {
  grid-column: 2;
  width: 100%;
  overflow: auto;
}

.two-col-container {
  display: grid;
  grid-template-columns: minmax($left-column-width, 10%) auto;
}

.main-container-res {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - (#{$toolbar-height} + 50px));
  columns: 1;
  padding: 16px;
}

.left-column {
  color: rgb(var(--grey-shade-dark));
  padding: 30px;
  margin-left: 115px;
}

.app-loading {
  .logo {
    width: 64px;
    height: 64px;
    background: url(../assets/logo.png) center center no-repeat;
    background-size: cover;
  }
}


.detailLabel {
  @extend .detailLabelFont;
  height: 15px;
  opacity: 1;
  display: block;
  color: #6F6F6F;
}

.detailLabelFont {
  font-size: 12px;
  font-family: $font-regular;

}

.detailLabelContent {
  height: 17px;
  font-size: 14px;
  opacity: 1;
  display: block;
  margin-top: 4px;
  padding-bottom: 39px;
}

.cardContent {
  padding-bottom: 3% !important;
}

.detailSectionlabel {
  @extend .detailSectionPadding;
  font-size: 12px;
  font-family: $font-regular;
  letter-spacing: 1.2px;
  color: #333333;
  opacity: 1;
  display: block;
}

.detailSectionPadding {
  padding-top: 10px;
  padding-bottom: 12px;
}



.header-image {
  background-image: url('../assets/volunteer.png');
  background-size: cover;
  height: 51px !important;
  width: 52px !important;
  border-radius: 50px;
}

.pageHeader {
  font-family: $font-ultra-light;
  color: rgb(var(--grey-shade-dark));
  font-size: 30px;
}

.filterOptions {
  color: #0197F4;
  width: 118px;
  height: 32px;
  border: 1px solid #0197F466;
  border-radius: 14px;
  opacity: 1;
  margin-left: 18px;
  text-align: center;
  font-family: $font-regular;
  padding-top: 1%;
  background: #0197F40A;
}

.recruitmentFlag {
  color: #2B3A67;
  width: 114px;
  height: 24px;
  border: 1px solid #f7ecc3;
  border-radius: 14px;
  opacity: 1;
  text-align: center;
  font-family: $font-regular;
  background: #f7ecc3 0% 0% no-repeat padding-box;

}


.ghost-button {
  color: #0197f4 !important;
  cursor: pointer !important;
}


.ghost-button:hover {
  background-color: #d4ecfb;
}

.navNodeHeader {
  font-size: 20px;
  color: #333333;
}

.pageHeader {
  font: $font-ultra-light;
  font-size: 32px;
  color: #333333;
}

.iconDetailText {
  font-size: 14px;
  opacity: 1;
}

.iconTextPadding {
  padding-right: 2% !important;
}

.recruitmentFlagIndicator {
  height: 8px;
  width: 8px;
  margin-right: 4px;
  background-color: #FFBF46;
  border-radius: 50%;
  display: inline-block;
}

.greyedMatCard {
  background-color: #8897BE14 !important;
}

.overrideWidth {
  width: 100%;
}

.sliderControlsPadding {
  padding-bottom: 24px;
}

.slider-content {
  padding-top: 24px;
  padding-bottom: 48px;
  padding-left: 40px;
  padding-right: 40px;
}

.slider-header-padding {
  padding-bottom: 24px;
}


.formLabel {
  margin-top: 3%;
  text-align: left;
  font-family: $font-light !important;
  letter-spacing: 0px;
  color: $form-label-color;
  opacity: 1;
  font-size: 14px !important;
  height: 15px;
  margin-bottom: 2%;
}

.form-control {
  border: 1.5px solid $form-border-color;
  margin-top: -3px;
  //width: 88% !important;
}

input[type="text"]:focus {
  border: 2px solid $form-selected-color;
}

input[type="email"]:focus {
  border: 2px solid $form-selected-color;
}

input[type="number"]:focus {
  border: 2px solid $form-selected-color;
}

textarea:focus {
  border: 2px solid $form-selected-color;
}

.form-control:focus {
  box-shadow: none;
}

.form-control.is-invalid {
  background-image: none;
  padding-right: inherit;
}

.customErrorDivColor {
  color: $form-error-color;
}

.required-field::after {
  content: "*";
  color: $form-error-color;
  margin-left: 2px;
}

.validationError {
  width: 350px;
  text-align: left;
  font-family: $font-light !important;
  color: $form-error-color;
  opacity: 1;
  font-size: 12px !important;
  margin-bottom: -4%;
}

@media screen and (min-width:767px) {
  .validationError {
    margin-bottom: -2% !important;
  }
}

.invalidLabel {
  color: $form-error-color;
}

.form-control.is-invalid {
  border: 2px solid $form-error-color !important;
  border-radius: 4px;
  opacity: 1;
  box-shadow: none !important;
}

.sectionLabel {
  text-align: left;
  font-family: $font-light !important;
  letter-spacing: 1.2px;
  color: $form-sectionLabel-color;
  opacity: 1;
  margin-bottom: 11px;
  margin-top: 20px;
  font-size: 12px;
  margin-left: -1%;
}

.iti {
  width: 100% !important;
}

.ngSelectInvalid {
  border: 2px solid $form-error-color !important;
  border-radius: 6px;
}

.navButton {
  float: right;
  margin-top: 2% !important;
}

.previousButton {
  margin-top: 2% !important;
}

.sliderheader {
  margin-top: 48px;
  margin-left: 3%;
  height: 24px;
  text-align: left;
  font-family: $font-light !important;
  font-size: 20px !important;
  letter-spacing: 0;
  color: #161616;
  opacity: 1;
}

.headerstatusIndicator {
  height: 8px;
  width: 8px;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
}

.headerstatusIndicatorLabel {
  font-family: $font-light !important;
  font-size: 14px !important;
  height: 14px;
}

.activeRow:hover {
  background-color: #8897BE14 !important;
  cursor: pointer;
}


.fx-half-gte-md {
  margin-top: 1.6%;
  margin-bottom: 1%;
}

.fx-full-gte-md {
  margin-top: 1%;
  margin-bottom: 0.7%;
}

.ngSelectInvalid.ng-select .ng-select-container {
  border: unset !important;
}

.ngSelectInvalid.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border: unset !important;
}

.icon-active {
  color: $link-color;
}

.formgap {
  margin-bottom: 10px;
}

.mat-tooltip-custom {
  font-size: 13px !important;
}

.mat-tooltip-multiline {
  font-size: 13px !important;
  white-space: pre-line;
}

.note-modal-backdrop {
  display: none !important;
}

.switchselectionText {
  margin-top: -31px;
  padding-left: 66px;
}

.switchselectionText label {
  margin-top: 1%;
  margin-bottom: 0px !important;
}

@media only screen and (max-width:1400px) {
  .switchselectionText label {
    margin-top: 2%;
  }
}

.activeButton {
  background: #0197F4;
  color: white;
}

.inactiveButton {
  background: #0197F40A;
}

.comingSoonFlag {
  color: white;
  width: fit-content;
  height: 24px;
  border: 1px solid #0197F4;
  border-radius: 14px;
  opacity: 1;
  text-align: center;
  font-family: $font-regular;
  background: #0197F4 0% 0% no-repeat padding-box;

}

.comingSoonFlagIndicator {
  height: 8px;
  width: 8px;
  margin-right: 4px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}
