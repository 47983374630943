@import "../base/color";

.overlay-container .toast-container .ng-toast {
  background-color: rgb($secondary-background-color);
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 1px 15px 1px;
  width: 450px;
  border-radius: 3px 3px 3px 3px;
  color: rgb($grey-shade-dark);
  pointer-events: all;
  cursor: pointer;
  top: 60px;
}

.border-p {
  border: 1px solid rgb($primary-color-dark);
  border-left: 10px solid rgb($primary-color-dark);
}

.border-w {
  border: 1px solid rgb($warning-color);
  border-left: 10px solid rgb($warning-color);
}

.border-s {
  border: 1px solid rgb($success-color);
  border-left: 10px solid rgb($success-color);
}

.border-a {
  border: 1px solid rgb($error-color);
  border-left: 10px solid rgb($error-color);
}

.custom-title {
  color: rgb($grey-shade-dark);
}

.custom-message {
  padding-left: 10px;
}

// Position
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

// Toast
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;

  .ngx-toastr {
    @include border-radius($alert-border-radius);
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 0.75rem 1.25rem 0.75rem 50px;
    width: 300px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
    pointer-events: auto;

    .toast-title {
      font-weight: bold;
    }
    .toast-message {
      word-wrap: break-word;
    }
    .toast-message a:hover {
      text-decoration: underline;
    }
    .toast-close-button {
      position: relative;
      right: -0.3em;
      top: -0.3em;
      float: right;
      font-size: 20px;
      font-weight: bold;
      color: inherit;
      text-shadow: 0 1px 0 #fff;
      opacity: 0.5;
      background: transparent;
      border: 0;
      padding: 0;
    }
    .toast-progress {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 4px;
      background-color: #000000;
      opacity: 0.3;
    }
    .toast-close-button:hover,
    .toast-close-button:focus {
      color: #000000;
      text-decoration: none;
      cursor: pointer;
      opacity: 0.75;
    }
  }
  .ngx-toastr:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    opacity: 1;
    cursor: pointer;
  }
}

@function str-split($string, $separator) {
  $split-arr: ();
  // first index of separator in string
  $index: str-index($string, $separator);
  // loop through string
  @while $index != null {
    // get the substring from the first character to the separator
    $item: str-slice($string, 1, $index - 1);
    // push item to array
    $split-arr: append($split-arr, $item);
    // remove item and separator from string
    $string: str-slice($string, $index + 1);
    // find new index of separator
    $index: str-index($string, $separator);
  }
  // add the remaining string to list (the last item)
  $split-arr: append($split-arr, $string);

  @return $split-arr;
}

@function svg-factory($fill-color, $viewbox, $path) {
  $split: str-split($viewbox, ' ');
  $width: nth($split, 3);
  $height: nth($split, 4);

  // opacity is 0.9999 otherwise it uses a hex equivelent
  // firefox requires fill rgb
  @return "%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='" + $viewbox +
    "' width='" + $width + "' height='" + $height + "'%3E%3Cpath fill='" +
    rgba($fill-color, 0.999999) + "' d='" + $path + "'/%3E%3C/svg%3E";
}
@function svg-encode($svg) {
  @return 'data:image/svg+xml;charset=utf8,' + $svg;
}
.toast-success {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/check.svg */
  background-image: url(svg-encode(
    svg-factory(
      $success-color,
      '0 0 512 512',
      'M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'
    )
  ));
  color: theme-color-level('success', 6);
  background-color: theme-color-level('success', -10);
  border: 1px solid theme-color-level('success', -9);
}
.toast-error {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/times-circle.svg */
  background-image: url(svg-encode(
    svg-factory(
      $error-color,
      '0 0 512 512',
      'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'
    )
  ));
  color: theme-color-level('danger', 6);
  background-color: theme-color-level('danger', -10);
  border: 1px solid theme-color-level('danger', -9);
}
.toast-info {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/info-circle.svg */
  background-image: url(svg-encode(
    svg-factory(
      $warning-color,
      '0 0 512 512',
      'M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'
    )
  ));
  color: theme-color-level('info', 6);
  background-color: theme-color-level('info', -10);
  border: 1px solid theme-color-level('info', -9);
}
.toast-warning {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/exclamation-triangle.svg */
  background-image: url(svg-encode(
    svg-factory(
      $warning-color,
      '0 0 576 512',
      'M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'
    )
  ));
  color: theme-color-level('warning', 6);
  background-color: theme-color-level('warning', -10);
  border: 1px solid theme-color-level('warning', -9);
}

// Responsive Design
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}


.overlay-container .toast-container .ng-toast {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 1px 15px 10px;
  width: 450px;
  border-radius: 3px 3px 3px 3px;
  pointer-events: all;
  cursor: pointer;
  top: 60px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  flex-direction: row;
  display: flex;
}

.border-p {
  border: 1px solid rgb($primary-color-dark);
  border-left: 5px solid rgb($primary-color-dark);
}

.border-w {
  border: 1px solid rgb($warning-color);
  border-left: 5px solid rgb($warning-color);
}

.border-s {
  border: 1px solid rgb($success-color);
  border-left: 5px solid rgb($success-color);
}

.border-a {
  border: 1px solid rgb($error-color);
  border-left: 5px solid rgb($error-color);
}

.custom-title {
  color: rgb($grey-shade-dark);
}

.custom-message {
  @include font-size($font-size-base);
  line-height: $line-height-base;
  color: rgb($grey-shade-dark);
}

.custom-toast-success {
  // icon-tick-circle-filled
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024' width='256' height='256'><path fill='rgb(84, 162, 103)' transform='translate(0,1000) scale(1,-1)' d='M512 853.333c-223.859 0-405.333-181.474-405.333-405.333s181.474-405.333 405.333-405.333c223.859 0 405.333 181.474 405.333 405.333v0c0 223.859-181.474 405.333-405.333 405.333v0zM743.253 554.667l-277.333-288c-5.763-6.051-13.881-9.814-22.878-9.814-0.057 0-0.114 0-0.171 0h0.009c-8.956 0.082-17.064 3.644-23.051 9.397l0.011-0.011-138.667 139.093c-2.797 4.656-4.451 10.275-4.451 16.28 0 17.673 14.327 32 32 32 6.766 0 13.041-2.1 18.21-5.683l-0.106 0.069 115.627-115.627 256 264.533c5.367 4.212 12.219 6.756 19.665 6.756 17.673 0 32-14.327 32-32 0-6.478-1.925-12.506-5.233-17.543l0.075 0.121z'/></svg>");
  color: rgb($success-color);
  background-color: #EEF8F1;
}

.custom-toast-error {
  // icon-error
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024' width='256' height='256'><path fill='rgb(187, 62, 83)' transform='translate(0,1000) scale(1,-1)' d='M512 864c-229.75 0-416-186.25-416-416s186.25-416 416-416c229.75 0 416 186.25 416 416v0c-0.243 229.653-186.348 415.758-415.977 416h-0.023zM705.28 299.947c5.782-5.789 9.359-13.784 9.359-22.613s-3.576-16.824-9.359-22.614v0c-5.789-5.782-13.784-9.359-22.613-9.359s-16.824 3.576-22.614 9.359v0l-148.053 148.053-148.053-148.053c-5.789-5.782-13.784-9.359-22.613-9.359s-16.824 3.576-22.614 9.359v0c-5.782 5.789-9.359 13.784-9.359 22.613s3.576 16.824 9.359 22.614v0l148.053 148.053-148.053 148.053c-5.321 5.699-8.588 13.376-8.588 21.815 0 17.673 14.327 32 32 32 8.44 0 16.116-3.267 21.834-8.606l-0.019 0.017 148.053-148.053 148.053 148.053c5.699 5.321 13.376 8.588 21.815 8.588 17.673 0 32-14.327 32-32 0-8.44-3.267-16.116-8.606-21.834l0.017 0.019-148.053-148.053z'/></svg>");
  color: rgb($error-color);
  background-color: #FAEFF1;
}

.custom-toast-info {
  // icon-info
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024' width='256' height='256'><path fill='rgb(57, 83, 147)' transform='translate(0,1000) scale(1,-1)' d='M512 885.333c-241.533 0-437.333-195.801-437.333-437.333s195.801-437.333 437.333-437.333c241.533 0 437.333 195.801 437.333 437.333v0c-0.243 241.435-195.899 437.091-437.31 437.333h-0.023zM512 74.667c-206.186 0-373.333 167.147-373.333 373.333s167.147 373.333 373.333 373.333c206.186 0 373.333-167.147 373.333-373.333v0c-0.484-205.991-167.342-372.849-373.287-373.333h-0.046zM554.667 661.333c0-23.564-19.103-42.667-42.667-42.667s-42.667 19.103-42.667 42.667c0 23.564 19.103 42.667 42.667 42.667s42.667-19.103 42.667-42.667zM597.333 309.333h-53.333v224c-0.237 17.577-14.423 31.763-31.977 32h-85.356c-17.673 0-32-14.327-32-32s14.327-32 32-32v0h53.333v-192h-53.333c-17.673 0-32-14.327-32-32s14.327-32 32-32v0h170.667c17.673 0 32 14.327 32 32s-14.327 32-32 32v0z'/></svg>");
  color: rgb($primary-color-dark);
  background-color: #EBF7FE;
}

.custom-toast-warning {
  // icon-caution
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024' width='256' height='256'><path fill='rgb(255, 204, 1)' transform='translate(0,1000) scale(1,-1)' d='M933.973 149.333l-379.307 637.44c-7.955 14.498-23.115 24.162-40.533 24.162s-32.579-9.664-40.413-23.923l-0.12-0.239-383.573-637.44c-3.258-5.885-5.176-12.903-5.176-20.368 0-23.564 19.103-42.667 42.667-42.667 1.824 0 3.622 0.114 5.386 0.337l-0.21-0.022h760.32c1.009-0.085 2.184-0.133 3.371-0.133 23.564 0 42.667 19.103 42.667 42.667 0 7.393-1.88 14.347-5.189 20.41l0.112-0.223zM480 576c0 17.673 14.327 32 32 32s32-14.327 32-32v0-181.333c0-17.673-14.327-32-32-32s-32 14.327-32 32v0zM512 192c-23.564 0-42.667 19.103-42.667 42.667s19.103 42.667 42.667 42.667c23.564 0 42.667-19.103 42.667-42.667v0c0-23.564-19.103-42.667-42.667-42.667v0z'/></svg>");
  color: rgb($warning-color);
  background-color: #FFFAF0;
}

.toast-container .ngx-toastr .toast-close-button {
  color: rgb($grey-shade-dark);
}

.toast-container .ngx-toastr .toast-title {
  color: rgb($grey-shade-dark);
}

.overlay-container .toast-container .ngx-toastr {
  background-position: 1rem 1rem ;
  width: 450px;
  background-size: 20px;
}

@media screen and (max-width:800px) {
  .toast-container .ngx-toastr .toast-close-button {
    font-size: 10px;
  }

  .overlay-container .toast-container .ngx-toastr {
    width: 25em;
    background-size: 15px;
    background-position: 1rem 0.75rem;
  }

  .toast-container .ngx-toastr .toast-close-button {
    color: rgb($grey-shade-dark);
  }

  .custom-message {
   font-size: 12px;
  }
}

