@use '@angular/material'as mat;
@import "@angular/material/theming";
@include mat-core();

$bv-orange: (50: #ffffff,
  100: #fff7f4,
  200: #fecdbd,
  300: #fc9977,
  400: #fc8259,
  500: #f5822b,
  600: #fa551d,
  700: #f44205,
  800: #d63a04,
  900: #b83204,
  A100: #ffffff,
  A200: #fff7f4,
  A400: #fc8259,
  A700: #f44205,
  contrast: (50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white));

$bv-blue: (50: #ffffff,
  100: #dde6f3,
  200: #b4c9e4,
  300: #7fa3d1,
  400: #6992c9,
  500: #2793e6,
  600: #4072b4,
  700: #38649d,
  800: #305687,
  900: #284770,
  A100: #ffffff,
  A200: #dde6f3,
  A400: #6992c9,
  A700: #38649d,
  contrast: (50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white));

$bv-orange-dark: (100: #fff7f4,
  500: #f5822b, // default color used by the theme
  700: #f44205,
  contrast: (100: $black-87-opacity,
    500: white,
    700: white));

$bv-blue-dark: (100: #dde6f3,
  500: #2ba3ff, // default color used by the theme ($secondary-color-dark)
  700: #38649d,
  contrast: (100: $black-87-opacity,
    500: white,
    700: white));

$candy-app-primary: mat.define-palette($bv-blue, 500);
$candy-app-accent: mat.define-palette($bv-orange, 500);
$candy-app-warn: mat.define-palette(mat.$red-palette);
$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
@include mat.all-legacy-component-themes($candy-app-theme);

.mat-button {
  padding: 0 6px 0 6px;
  margin: 6px 8px 6px 8px;
  min-width: 88px;
  border-radius: 3px;
  font-size: $font-size-root;
  text-align: center;
  text-decoration: none;
  border: none;
  outline: none;
}


.mat-raised-button {
  padding: 0 6px 0 6px;
  margin: 6px 8px 6px 8px;
  min-width: 88px;
  height: $mat-button-height;
  border-radius: 3px;
  font-size: $font-size-root;
  text-align: center;
  text-decoration: none;
  border: none;
  outline: none;
}

.mat-menu-item {
  font-size: $font-size-root;
}

.mat-button-wrapper {
  font-family: $font-regular;
}

.mat-button:focus,
.mat-raised-button:focus {
  outline: none;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar .h1,
.mat-toolbar h2,
.mat-toolbar .h2,
.mat-toolbar h3,
.mat-toolbar .h3,
.mat-toolbar h4,
.mat-toolbar .h4,
.mat-toolbar h5,
.mat-toolbar .h5,
.mat-toolbar h6,
.mat-toolbar .h6 {
  font-family: $font-light;
  font-size: $font-size-root - 2;
}



.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary {
  background-color: #f5822b;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: #f5822b;
  border-color: #f5822b;
}

.mat-menu-panel {
  max-width: 100% !important;
  min-height: auto !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0);
}

.mat-drawer.mat-drawer-end {
  right: 0;
  transform: translate3d(0, 0, 0) !important;
}

.mat-form-field-flex {
  height: 42px !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0em 0 0em 0;
}


.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #666;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline {
  color: #bb3e53 !important;
  border-radius: 4px;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #0197f4;
}


.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2793e6 !important;
}

.mat-radio-inner-circle {
  background-color: #2793e6 !important;
}

.mat-radio-label-content {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: #2793e6 !important;
  box-shadow: none !important;
  border: 2px solid #2793e6 !important;
}

.ng-select .ng-select-container {
  color: $form-border-color !important;
  background-color: #fff;
  border-radius: 4px;
  border: 1.5px solid #666 !important;
  min-height: 36px;
  align-items: center;
}

.mat-step-header .mat-step-icon-state-done {
  border: unset !important;
  background-color: unset !important;
  margin-top: 4px;
}

.step-icon {
  color: #2793e6 !important;
}

.mat-step-header .mat-step-icon-state-edit {
  background-color: #2793e6 !important;
  border: unset !important;
}

.mat-step-icon.mat-step-icon-state-number {
  background-color: unset !important;
  border: 2px rgba(0, 0, 0, 0.54) solid !important;
}

.mat-step-icon.mat-step-icon-state-number.mat-step-icon-selected {
  background-color: #2793e6 !important;
  border: unset !important;
}

.mat-step-icon.mat-step-icon-state-number .mat-step-icon-content {
  display: none !important;
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: #2793e6 !important;
}

.mat-progress-bar {
  padding-left: 24px;
}

// Style can be used if the stepper shouldn't be extended to full width of page.
// .mat-step-full-width .mat-horizontal-stepper-header-container {
//   min-width: 100% !important;
// }

// .mat-stepper-label-position-end .mat-horizontal-stepper-header-container {
//   max-width: 100% !important;
//   width: fit-content !important;
//   align-items: center;
// }

.mat-stepper-horizontal-line {
  border: unset !important;
}


.mat-horizontal-stepper-header-container {
  overflow-x: auto;
}

.mat-step-header {
  min-width: fit-content;
}


.mat-step-icon {
  height: 15px !important;
  width: 15px !important;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: none !important;
  cursor: auto !important;
}

.mat-redio-vertical-list {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.mat-checkbox-layout {
  white-space: normal !important;
  display: block !important;
}

.mat-checkbox-frame,
.mat-expansion-indicator:after,
.mat-radio-outer-circle {
  border-color: #2793e6 !important;
}

.mat-expansion-indicator {
  height: 27px !important;
  padding-left: 5px;
}

.stepper-container .mat-expansion-panel-content {
  visibility: inherit !important;
}

@media screen and (max-width:500px) {
  .mat-stepper-mobile-header .mat-step-header {
    flex-direction: column !important;
  }

  .mat-stepper-mobile-header .mat-horizontal-stepper-header {
    padding-top: 8% !important;
  }
}

/* Mat-Button Styles */

.mat-raised-button.mat-primary:hover:enabled {
  background-color: #D6752C;
}

.mat-stroked-button.mat-primary:hover:enabled {
  background-color: #FEEFE4;
}

.mat-raised-button.mat-primary:active:enabled {
  background-color: #C66F2D;
}

.mat-stroked-button.mat-primary:active:enabled {
  background-color: #FDE7D6;
}

.mat-stroked-button.mat-primary {
  border-color: #f5822b;
}

mat-carousel > div {
  border-radius: 12px !important;
}

mat-carousel > .carousel > .carousel-indicators {
  left: 35% !important;
  bottom: -4% !important; 
}

mat-carousel > .carousel > .carousel-list > .carousel-slide > .carousel-slide > .carousel-slide-content {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
  cursor: default !important;
}

mat-carousel > .carousel > .carousel-indicators .mat-mini-fab.mat-accent {
  background-color: rgba(0, 0, 0, .12);  
  box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%), 0 0 0 0 rgb(0 0 0 / 12%);
}

mat-carousel > .carousel > .carousel-indicators .mat-button-disabled {
  background-color: #f5822b !important; 
}

mat-carousel > .carousel > button:first-of-type {
  left: 5px !important;
  font-size: 20px !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

mat-carousel > .carousel > button:last-of-type {
  right: 5px !important;
  font-size: 20px !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

mat-carousel > .carousel .material-icons {
  font-size: 21px !important;  
  background-color: #f18e43;
  border-radius: 12px;
  color: rgb(217, 216, 216);
}

/* End */