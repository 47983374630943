@import "./../base/variables";
@import "./../themes/material";

.sv_main .sv_body {
  border-top: unset !important;
}

.sv_nav input[type=button].sv_complete_btn, .sv_nav input[type=button].sv_preview_btn, .sv_nav input[type=button].sv_next_btn, .sv_nav input[type=button].sv_prev_btn {
  @extend .mat-raised-button;
  @extend .mat-primary;
  font-family: $font-regular;
}
  
span.sv-string-viewer {
  font-size:  $font-size-root !important;
}

.sv_q_required_text {
  color: $form-error-color;
}

.sv_main .sv_progress_bar {
  background-color: $form-selected-color;
}

.sv_nav input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]):focus
{
  @extend .form-control;
  border: 2px solid $form-selected-color !important;
}

.sv_main .sv_q_rating_item.active .sv_q_rating_item_text {
  background-color: $form-selected-color !important;
  border-color: $form-selected-color !important;
  color: #fff;
}

.sv_main .sv_q_rating_item .sv_q_rating_item_text:hover {
  border: 1px solid $form-selected-color !important;
}

.sv_main input[type=button], .sv_main button, .sv_q_file_choose_button, .sv_main.sv_main .sv-action-bar-item.sv_edit_btn {
  color:  $form-selected-color ;
  background-color: #fff;
}

.sv_main input[type=button]:hover, .sv_main button:hover {
  background-color: #d4ecfb;
}

.sv_main .sv_p_root>.sv_row:nth-child(even) {
  background-color: unset !important;
}
.sv_main .sv_p_root>.sv_row {
  border-bottom: unset !important;
}

.sv_main .sv_select_wrapper:before {
  background-color: $form-selected-color;
}

.sv_main .sv_q_imgsel.checked label>div {
  background-color: $form-selected-color;
}

button.sjs_sp_clear, .sv-paneldynamic__add-btn.sv-paneldynamic__add-btn--list-mode, .sv_q_file_choose_button, button.sv_edit_btn {
  border: 1px solid;
}

button.sjs_sp_clear:hover, .sv_q_file_choose_button:hover, button.sv_edit_btn:hover {
  background-color: #d4ecfb !important;
}

canvas {
  border: 1px solid;
}

.sv_main .sv-boolean__slider {
  background-color: $form-selected-color;
}

.sv_main .sv-boolean__switch {
  background-color: #d4ecfb;
  outline-color: white;
}

.sv_main .sv_container .sv_body {
  padding-left: unset;
  padding-right: unset;
}

.sv_main .sv_q_file_remove:hover {
  @extend .ghost-button
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type=radio]:focus, .sv_main .sv_container .sv_body .sv_p_root .sv_q input[type=checkbox]:focus {
  outline: 1px dotted $form-selected-color;
}

.sv-ranking-item__icon {
  fill: unset !important;
}

.sv_main .sv_q_dropdown__filter-string-input {
  border: none !important;
}

.sv_main .sv_q_other input:focus, .sv_main .sv_q_text_root:focus, .sv_main .sv_q_dropdown_control:focus, .sv_main input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]):focus, .sv_main select:focus, .sv_main textarea:focus {
  border: 2px solid $form-selected-color !important;
}