/**********************************************************/
/*       global variables
/*****************************************************************/

$font-size-root: 14px;
$section-title-font-size: 16px;
$tab-font-size: 18px;
$icon-font-size: 20px;
$toolbar-icon-font-size: 16px;
$mat-button-height: 40px;
$css-icon-size: 40px;
$form-selected-color: #0197f4;
$form-error-color: #c33149;
$form-border-color:#666;
$form-label-color:#333;
$form-sectionLabel-color: #6f6f6f;
$form-sectionHeader-color: #333333;
$link-color: #2ba3ff;
$text-light-color: #707070;
$detail-sectionHeader-color: #3752a2;
$detail-sectionLabel-color: #8d8d8d;
$detail-sectionValue-color: #59616f;

$main-container-padding: 30px;
$section-title-margin: 20px;
$slider-container-margin: 40px;
$table-header-padding: 10px;
$launcher-tab-margin: 70px;
$launcher-icon-margin: 40px;
$left-column-width: 435px;
$toolbar-height: 50px;

$font-ultra-light: LibreFranklin-Light;
$font-light: LibreFranklin-Regular;
$font-regular: LibreFranklin-Medium;
$font-medium: LibreFranklin-SemiBold;


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
