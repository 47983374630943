/**********************************************************/
/*    Buttons
/*****************************************************************/

html * {
  font-family: $font-light;
}

body {
  overflow-y: visible;
  padding-right: 0;
  color: rgb($grey-shade-dark);
  margin: 0;
}

h1 {
  display: block;
  font-family: $font-light;
  color: rgb($grey-shade-dark);
  font-size: $font-size-root + 10px;
  line-height: 50px;
}

h2 {
  display: block;
  font-family: $font-light;
  color: rgb($grey-shade-dark);
  font-size: $font-size-root + 8px;
  line-height: 50px;
}

h3 {
  display: block;
  font-family: $font-regular;
  color: rgb($grey-shade-dark);
  font-size: $font-size-root + 6px;
  line-height: 45px;
}

h4 {
  display: block;
  font-family: $font-regular;
  color: rgb($grey-shade-dark);
  font-size: $font-size-root + 4px;
  line-height: 40px;
}

h5 {
  display: block;
  font-family: $font-light;
  color: rgb(var(--grey-shade-dark));
  font-size: 22px;
}

h6 {
  display: block;
  font-family: $font-medium;
  color: rgb($grey-shade-dark);
  font-size: $font-size-root;
  line-height: 30px;
}

a {
  color: rgb($app-link-color);
  font-family: $font-light;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
