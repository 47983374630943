/**********************************************************/
/*    Colour Palette
/*****************************************************************/
:root {
  --base-color-white: 255, 255, 255; // #ffffff
  --primary-color-dark: 87, 110, 175; // #576EAF
  --primary-color-light: 149, 158, 204; // #959ecc
  --grey-shade-dark: 51, 51, 51; // #333333
  --grey-shade-medium: 102, 102, 102; // #666666
  --grey-shade-light: 153, 153, 153; // #999999
  --error-color: 187, 62, 83; // #bb3e53
  --success-color: 84, 162, 103; // #54a267
  --warning-color: 255, 204, 1; // #ffcc01
  --secondary-background-color: 66, 66, 66; // #424242
}

$base-color-white: var(--base-color-white);
$primary-color-dark: var(--primary-color-dark);
$primary-color-light: var(--primary-color-light);
$grey-shade-dark: var(--grey-shade-dark);
$grey-shade-medium: var(--grey-shade-medium);
$grey-shade-light: var(--grey-shade-light);
$app-link-color: var(--link-color);
$grey-shade-light: var(--grey-shade-light);
$error-color: var(--error-color);
$success-color: var(--success-color);
$warning-color: var(--warning-color);
$secondary-background-color: var(--secondary-background-color);
